import { template as template_feb9f1498bed4733a91e2d3adb9f1534 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_feb9f1498bed4733a91e2d3adb9f1534(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
