import { template as template_b4a64c7a20024542941e587d2470e868 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b4a64c7a20024542941e587d2470e868(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
