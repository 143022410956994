import { template as template_8f7a92e120d14dc49349556ec5affac1 } from "@ember/template-compiler";
const FKLabel = template_8f7a92e120d14dc49349556ec5affac1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
