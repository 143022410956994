import { template as template_c940dad32c0144b1b153beb02007004b } from "@ember/template-compiler";
const FKText = template_c940dad32c0144b1b153beb02007004b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
